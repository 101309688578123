import styled from 'styled-components'
import { TopBarWrapper } from '@shopify/polaris'

export const TopBar = styled(TopBarWrapper)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
`

export const MobileTopBar = styled(TopBarWrapper)`
  justify-content: space-between;

  & > div {
    justify-content: flex-end;
  }
`

export const MobileHamburgerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
`
