import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import config from 'config'

interface Props {
  url: string
}

const CustomLink: FC<Props> = ({ url, children, ...rest }) => {
  const isExternalUrl = url.startsWith(config.SHOPIFY_ADMIN_URL)

  return isExternalUrl ? (
    <a target="_blank" rel="noopener noreferrer" href={url} {...rest}>
      {children}
    </a>
  ) : (
    <Link to={url} {...rest}>
      {children}
    </Link>
  )
}

export default CustomLink
