import * as React from 'react'
import { Icon, Logo } from '@shopify/polaris'
import { MobileHamburgerMajorMonotone } from '@shopify/polaris-icons'

import { MobileTopBar, MobileHamburgerWrapper } from './styles'

interface Props {
  handleToggleMobileNavigation: () => void
}

const MobileNav: React.FC<Props> = ({ handleToggleMobileNavigation }) => (
  <MobileTopBar onlyMobile>
    <MobileHamburgerWrapper onClick={handleToggleMobileNavigation}>
      <Icon source={MobileHamburgerMajorMonotone} color="white" />
    </MobileHamburgerWrapper>
    <Logo onlyMobile />
  </MobileTopBar>
)

export default MobileNav
