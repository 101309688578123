export default {
  colors: {
    topBar: {
      background: '#77C7E7',
      backgroundDarker: '#40B4E5',
      backgroundLighter: '#A3D9F0',
      color: '#FFFFFF',
    },
  },
  logo: {
    width: 100,
    topBarSource: '/logo.svg',
    accessibilityLabel: 'AiFi',
    url: '/',
  },
}
