import React, { FC } from 'react'
import { Flex } from 'rebass'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Navigation, Logo } from '@shopify/polaris'

import { TopBar } from './styles'

const sideNavItems = (onClick: () => void) => [
  // {
  //   label: 'Store Directory',
  //   icon: 'onlineStore',
  //   url: '/stores',
  //   onClick: onClick,
  // },
  // {
  //   label: 'Product Directory',
  //   icon: 'products',
  //   url: '/products',
  //   onClick: onClick,
  // },
  // {
  //   label: 'Store management',
  //   icon: 'home',
  //   url: '/store-management',
  //   onClick: onClick,
  // },
  // {
  //   label: 'Contests',
  //   icon: 'notes',
  //   url: '/contested-orders',
  //   onClick: onClick,
  // },
]

interface InnerProps {
  location: {
    pathname: string
  }
  hideMobileNavigation: () => void
}

type Props = InnerProps & RouteComponentProps

const SideNav: FC<Props> = ({ location, hideMobileNavigation }) => (
  <Flex flexDirection="column">
    <TopBar>
      <Logo />
    </TopBar>
    <Navigation location={location.pathname}>
      <Navigation.Section items={sideNavItems(hideMobileNavigation)} />
    </Navigation>
  </Flex>
)

export default withRouter(SideNav)
