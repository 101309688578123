let isAvailable = true

let inMemeoryStorage: any = {}

try {
  const storageType = typeof window.sessionStorage
  isAvailable = storageType === 'object'
} catch (e) {
  console.error('Session storage is disabled', e)
  isAvailable = false
}

export const storage: Storage = isAvailable
  ? window.sessionStorage
  : {
      setItem: (key: string, value: string) => (inMemeoryStorage[key] = value),
      getItem: (key: string) => inMemeoryStorage[key] || null,
      removeItem: (key: string) => delete inMemeoryStorage.key,
      key: (index: number) => Object.keys(inMemeoryStorage)[index],
      clear: () => (inMemeoryStorage = {}),
      length: 0,
    }
