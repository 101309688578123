export default {
  products: '/products',
  stores: '/stores',
  contestedOrders: '/contested-orders',
  auth: {
    error: '/auth/error',
  },
  storeManagement: '/store-management',
  information: '/information',
}
