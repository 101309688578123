import Cookies from 'js-cookie'

const prefix = 'REACT_APP_'
const DEV_SHOP = 'aifi-dev-test-store.myshopify.com'
const shopOrigin = Cookies.get('shopOrigin') || DEV_SHOP

const keys = {
  NODE_ENV: 'NODE_ENV',
  API_SERVER_URL: 'API_SERVER_URL',
  SHOPIFY_API_KEY: 'SHOPIFY_API_KEY',
  DEBUG_SHOPIFY_APP: 'DEBUG_SHOPIFY_APP',
  MAPBOX_ACCESS_TOKEN: 'MAPBOX_ACCESS_TOKEN',
}

declare global {
  interface Window {
    APP_CONFIG: any
  }
}

const getConfig = (key: string, defaultValue: string | boolean, hasPrefix = true) => {
  if (window.APP_CONFIG && window.APP_CONFIG[`${prefix}${key}`]) {
    return window.APP_CONFIG[`${prefix}${key}`]
  } else if (process.env[`${prefix}${key}`] && hasPrefix) {
    return process.env[`${prefix}${key}`] || ''
  } else if (process.env[key] && !hasPrefix) {
    return process.env[key]
  } else {
    return defaultValue
  }
}

const apiServerUrl = getConfig(keys.API_SERVER_URL, '')

const config = {
  API_PREFIX: `${apiServerUrl}/api/admin`,
  ENV: getConfig(keys.NODE_ENV, 'production', false),
  SHOPIFY_ADMIN_URL: `https://${shopOrigin}/admin`,
  SHOPIFY_SHOP_ORIGIN: shopOrigin,
  SHOPIFY_API_KEY: getConfig(keys.SHOPIFY_API_KEY, ''),
  MAPBOX_API_KEY: getConfig(keys.MAPBOX_ACCESS_TOKEN, ''),
  DEBUG_SHOPIFY_APP: Boolean(getConfig(keys.DEBUG_SHOPIFY_APP, false)),
}

export default config
