import ky from 'ky'

import config from 'config'
import { storage } from 'utils/storage'

const ACCESS_TOKEN = 'accessToken'
const ACCESS_TOKEN_EXPIRATION = 'accessTokenExpiration'

export const getToken = async () => {
  if (config.ENV === 'development') {
    return
  }

  const token = storage.getItem(ACCESS_TOKEN)
  const tokenExpiration = storage.getItem(ACCESS_TOKEN_EXPIRATION)

  if (token && tokenExpiration && new Date() < new Date(tokenExpiration)) {
    return token
  }

  try {
    const data = await ky('auth/token', {
      prefixUrl: config.API_PREFIX,
      searchParams: window.location.search,
    }).json<AuthApiResponse>()

    storage.setItem(ACCESS_TOKEN, data.token)
    storage.setItem(ACCESS_TOKEN_EXPIRATION, data.expiration)
    return data.token
  } catch (error) {
    throw error
  }
}
