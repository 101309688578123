import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppProvider, Frame } from '@shopify/polaris'
import '@shopify/polaris/styles.css'

import GlobalStyles from 'styles/global'

import CustomLink from './components/CustomLink'
import PageLoader from './components/Loaders/PageLoader'
import { SideNav, MobileNav } from './components/Navigation'
import theme from './styles/theme'
import routes from './pages/routes'
import * as authApi from './api/auth'

// const Stores = React.lazy(() => import('./pages/Stores'))
// const StoreDetail = React.lazy(() => import('./pages/StoreDetail'))
// const Products = React.lazy(() => import('./pages/Products'))
// const ProductDetail = React.lazy(() => import('./pages/ProductDetail'))
const AuthError = React.lazy(() => import('./pages/AuthError'))
const IntegrationInformation = React.lazy(() => import('./pages/IntegrationInformation'))
// const StoreManagement = React.lazy(() => import('./pages/StoreManagement'))
// const ContestedOrders = React.lazy(() => import('./pages/ContestedOrders'))
// const ContestedOrderDetail = React.lazy(() => import('./pages/ContestedOrderDetail'))

interface State {
  showMobileNavigation: boolean
  isAuthenticating: boolean
}

class App extends React.Component<{}, State> {
  public state: State = {
    showMobileNavigation: false,
    isAuthenticating: true,
  }

  public componentDidMount = async () => {
    await authApi.getToken()
    this.setState(() => ({
      isAuthenticating: false,
    }))
  }

  private handleToggleMobileNavigation = () => {
    this.setState(({ showMobileNavigation }) => ({ showMobileNavigation: !showMobileNavigation }))
  }

  private hideMobileNavigation = () => {
    this.setState({ showMobileNavigation: false })
  }

  public render() {
    const { showMobileNavigation, isAuthenticating } = this.state

    return (
      <AppProvider theme={theme} linkComponent={CustomLink}>
        <Frame
          navigation={<SideNav hideMobileNavigation={this.hideMobileNavigation} />}
          showMobileNavigation={showMobileNavigation}
          onNavigationDismiss={this.handleToggleMobileNavigation}
        >
          <GlobalStyles />
          <MobileNav handleToggleMobileNavigation={this.handleToggleMobileNavigation} />
          {isAuthenticating ? (
            <PageLoader />
          ) : (
            <React.Suspense fallback={<PageLoader />}>
              <Switch>
                <Redirect exact from="/" to={routes.information} />
                {/*<Route exact path={routes.stores} component={Stores} />*/}
                {/*<Route path={`${routes.stores}/:id`} component={StoreDetail} />*/}
                {/*<Route exact path={routes.products} component={Products} />*/}
                {/*<Route path={`${routes.products}/:id`} component={ProductDetail} />*/}
                {/*<Route path={routes.storeManagement} component={StoreManagement} />*/}
                {/*<Route exact path={routes.contestedOrders} component={ContestedOrders} />*/}
                {/*<Route*/}
                {/*  path={`${routes.contestedOrders}/:contestedOrderId`}*/}
                {/*  component={ContestedOrderDetail}*/}
                {/*/>*/}

                <Route path={`${routes.auth.error}`} component={AuthError} />
                <Route path={`${routes.information}`} component={IntegrationInformation} />
              </Switch>
            </React.Suspense>
          )}
        </Frame>
      </AppProvider>
    )
  }
}

export default App
