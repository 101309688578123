/* eslint-disable no-undef  */

import config from 'config'

export const initShopifySDK = () =>
  window.ShopifyApp.init({
    apiKey: config.SHOPIFY_API_KEY,
    shopOrigin: `https://${config.SHOPIFY_SHOP_ORIGIN}`,
    debug: config.DEBUG_SHOPIFY_APP,
    forceRedirect: config.ENV === 'production',
  })

type GetAnalyticsType = <T>(query: string) => Promise<AnalyticsFetchResponse>

export const getAnalyticsData: GetAnalyticsType = (query: string) =>
  new Promise((resolve, reject) =>
    window.ShopifyApp.ready(() => {
      window.ShopifyApp.Analytics.fetch({
        query,
        success: response => resolve(response),
        error: response => reject(response),
      })
    })
  )

export const shopifyRedirect = window.ShopifyApp.redirect
