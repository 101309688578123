import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import history from 'utils/history'
import { initShopifySDK } from 'utils/shopifyApp'

import App from './App'

initShopifySDK()

history.listen((location, action) => {
  initShopifySDK()
})

const rootDom = document.getElementById('root')

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  rootDom
)
